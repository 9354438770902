<template>
  <v-dialog v-model="form" persistent max-width="1000px">
    <v-card>
      <v-card-text>
        <v-container class="mb-n9">
          <v-card elevation="0">
            <v-card-title>
              Selecione os Tipos de Solicitações que deseja inserir para este fornecedor:
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-row align="center">
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-container>
          <v-row style="display: contents" justify="center">
            <div>
              <v-data-table
                :loading="loadingSolicitation"
                :headers="headers"
                :items="items"
                item-key="id"
                :selection="selectedItems"
                hide-selected
                :search="search"
                class="mb-n9"
              >
                <template v-slot:item="{ item }">
                  <tr @click="toggleSelection(item)">
                    <td style="display: flow">
                      <v-checkbox v-model="item.isSelected" primary hide-details></v-checkbox>
                    </td>
                    <td>{{ item.display_name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-row>
          <v-card-text @click="selectAll">
            <div style="display: flex; align-items: end;">
              <v-checkbox v-model="selectAllValues" hide-details>
              </v-checkbox>
              <label>Selecionar Todos</label>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveDialog" :loading="loadingButtonDialog" class="primary" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
export default {
  props: {
    loadingButtonDialog: {
      type: Boolean,
      default: false
    },
    providerId: {
      default: ''
    }
  },
  data() {
    return {
      search: "",
      loadingSolicitation: false,
      selectAllValues: false,
      selectedItems: [],
      items: [],
      form: {},
      headers: [
        { text: 'Selecionar', value: 'selected', sortable: false },
        { text: 'Nome', value: 'display_name' },
      ],
      loadingButton: false,
    };
  },
  async mounted() {
    this.loadingSolicitation = true;
    await this.loadDefaultSolicitationType().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.items = this.default_solicitation_type_list;
    this.loadingSolicitation = false;
  },
  computed: {
    ...mapState("solicitationType", ["default_solicitation_type_list"]),
  },
  methods: {
    ...mapActions("solicitationType", ["loadDefaultSolicitationType"]),
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveDialog: async function () {
      this.loadingButtonDialog = true;
      if (this.selectedItems.length == 0) {
        await this.$swal("Oops...", "Selecione uma solicitação", "warning");
        this.loadingButtonDialog = false;
        return;
      }
      const params = {
        provider_id: this.providerId,
        solicitations: this.selectedItems
      };

      this.$emit("saveDialog", params);
    },
    toggleSelection(item) {
      const index = this.selectedItems.findIndex(selected => selected.id === item.id);
      if (item.isSelected) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    selectAll() {
      let setSelected = false;
      if (this.selectAllValues) {
        setSelected = true;
        this.selectedItems = [...this.items];
      } else {
        this.selectedItems = [];
      }
      this.items.forEach(item => {
        item.isSelected = setSelected;
      });
    }
  },
  watch: {
    selectedItems: {
      handler() {
        this.selectAllValues = this.items.every(item => item.isSelected);
      },
    },
  },
};
</script>