<template>
  <div class="sub-item py-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          v-model="selectedTags"
          :items="tag_list.data"
          chips
          outlined
          label="Tags"
          item-text="name"
          return-object
          :rules="defaultRules"
          multiple
          dense
          @change="save(selectedTags)"
          :loading="loadingTags"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
              color="primary"
              outlined
              small
            >
              <v-icon left small>
                {{ data.item.icon }}
              </v-icon>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-icon>
              <v-icon v-text="data.item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["company_id", "tags_selected", "required"],
  data() {
    return {
      loading: true,
      defaultRules: [],
      dialogAdd: false,
      selectedTags: [],
      loadingTags: true
    };
  },

  async mounted() {
    if (this.required) {
      this.defaultRules = [v => !!v || "Campo obrigatório"];
    }
    await this.loadTag().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.selectedTags = this.tags_selected;
    this.save();
    this.loadingTags = false;
  },
  computed: {
    ...mapState("categories", ["associatedCategories"]),
    ...mapState("solicitationType", ["new"]),
    ...mapState("tag", ["tag_list"])
  },
  methods: {
    ...mapActions("categories", [
      "loadAssociatedCategories",
      "loadCategoriesList"
    ]),
    ...mapActions("tag", ['loadTag']),

    remove(item) {
      this.selectedTags.splice(
        this.selectedTags.findIndex(
          elementRemove => elementRemove.id === item.id
        ),
        1
      );
      this.save();
    },
    save() {
      this.$emit('save', this.selectedTags);
    }
  }
};
</script>
